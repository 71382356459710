<template>
  <div>
    <Modal
      :ok-text="text.save"
      name="editMachineTool"
      @cancel="closeEditModal"
      @ok="editMachineToolFromModal"
      :show="showEditModal"
      :title="text.edit_mt_title[language]"
    >
      <template slot="body">
        <span class="machine-tools__margin" style="font-size: 18px; font-weight: 600">
          {{ row.machine_tool_name }} ({{ row.manufacturing }})
        </span>
        <Input
          :label="text.serial_number[language]"
          style="width: 100%"
          :default-value="row.serial_number"
          @change="saveEdit($event, 'serial_number')"
        />
        <Select
          :values="shops"
          @change="saveEdit($event.value, 'id_shop')"
          style="width: 100%"
          :default-value="row.shop_name"
          :label="text.shop_name[language]"
          :dropdown-props="{
            label: 'name',
            value: 'id',
          }"
          :position-type="'fixed'"
        />
        <Select
          :label="text.accuracy_class[language]"
          :defaultValue="row && row.accuracy_class ? row.accuracy_class : ''"
          :values="accuracyClasses"
          @change="saveEdit(+$event.value, 'id_current_accuracy_class')"
          :position-type="'fixed'"
          :dropdown-props="{
            label: 'name',
            value: 'id',
          }"
        />
      </template>
    </Modal>
    <Modal
      name="addMachineTool"
      @cancel="closeDeleteModal"
      @ok="deleteMachineTool"
      :okText="text.delete"
      :show="showDeleteModal"
      :title="text.delete_title[language]"
    >
      <template slot="body">
        <div class="component__margin">
          <span style="font-size: 18px"
            >{{ text.delete_text[language] }}
            <b>{{ row.serial_number }} {{ row.machine_tool_name }}</b
            >?
          </span>
        </div>
      </template>
    </Modal>
    <Modal
      name="addMachineTool"
      @cancel="setShowMaterialModal(false)"
      :okText="text.save"
      :show="showMaterialModal"
      :hide-footer="true"
      :title="text.set_machine_materials_title[language]"
    >
      <template slot="body">
        <div class="component__margin">
          <a-form
            :form="form"
            @submit="saveMaterialForMt"
            class="block block__column block__between"
          >
            <div style="height: 100%; overflow-y: auto">
              <a-form-item label="Материал">
                <a-tree-select
                  v-if="materialsData && mtData"
                  v-decorator="[
                    'materials',
                    {
                      initialValue: mtData,
                      rules: [
                        {
                          required: true,
                          message: 'Поле обязательное для заполнения',
                        },
                      ],
                    },
                  ]"
                  tree-checkable
                  :show-checked-strategy="SHOW_PARENT"
                  :style="{ width: '100%' }"
                  show-search
                  treeNodeFilterProp="title"
                  :tree-data="materialsData"
                />
              </a-form-item>
            </div>
            <div style="padding: 10px" class="block block__right">
              <Button
                type="default"
                :text="text.cancel[language]"
                @click="setShowMaterialModal(false)"
              />
              <Button
                type="primary"
                html-type="submit"
                class="component__margin-left"
                :text="text.save[language]"
              />
            </div>
          </a-form>
        </div>
      </template>
    </Modal>
    <a :href="`https://market.i5.solutions/machine_tool/${row.uuid}`" target="_blank">
      <Button block :text="text.view[language]" class="page__edit_margin" />
    </a>
    <Button
      class="machine-tools__margin"
      size="small"
      block
      @click="openEditModal"
      :text="text.edit[language]"
    />
    <Button
      class="machine-tools__margin"
      size="small"
      block
      @click="openDeleteModal"
      :text="text.delete[language]"
    />
    <Button
      class="machine-tools__margin"
      size="small"
      block
      @click="setShowMaterialModal(true)"
      :text="text.set_machine_materials[language]"
    />
    <div class="machine-tools__margin" style="width: 100%">
      <span class="machine-tools__mt">{{ text.mt[language] }}</span>
    </div>
    <div class="block block__center">
      <img v-if="row.img" :src="row.img" class="machine-tools__image" />
    </div>
    <div class="machine-tools__params_title">{{ text.machine_tool_name[language] }}:</div>
    <div class="machine-tools__margin machine-tools__params_value">
      {{ row.name }}
    </div>
    <div class="machine-tools__params_title">{{ text.manufacturing[language] }}:</div>
    <div class="machine-tools__margin machine-tools__params_value">
      {{ row.company_name }}
    </div>
    <div class="machine-tools__params_title">{{ text.machine_tool_method[language] }}:</div>
    <div class="machine-tools__margin machine-tools__params_value">
      {{ row.method }}
    </div>
    <div class="component__margin-bottom">
      <div class="machine-tools__params_title component__margin-bottom">
        {{ text.inner_size[language] }}
      </div>
      <div class="machine-tools__margin machine-tools__params_value">
        {{ row.inner_size }}
      </div>
    </div>
  </div>
</template>

<script>
import Input from "@/components/input/Input.vue";
import Modal from "@/components/modal/Modal.vue";
import Select from "@/components/select/Select.vue";
import Button from "@/components/button/Button.vue";
import text from "@/assets/text/machine_tools.json";
import { query } from "@/utilities/axios";
import { TreeSelect } from "ant-design-vue";

const SHOW_PARENT = TreeSelect.SHOW_PARENT;
export default {
  name: "MachineEdit",
  data() {
    return {
      text,
      SHOW_PARENT,
      form: this.$form.createForm(this, { name: "add_material" }),
      timer: null,
      showAddModal: false,
      showStandardModal: false,
      showEditModal: false,
      showDeleteModal: false,
      serialNumber: null,
      laborHourPrice: null,
      idShop: null,
      idAccClass: null,
      shops: [],
      accuracyClasses: [],
      showMaterialModal: false,
      mtData: null,
      materialsData: null,
    };
  },
  props: {
    row: Object,
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    openCalcModal() {
      this.showStandardModal = true;
    },
    openEditModal() {
      if (this.shops.length === 0) {
        this.getShops();
      }
      this.showEditModal = true;
    },
    closeEditModal() {
      this.showEditModal = false;
      this.$emit("update");
    },
    closeCalcModal() {
      this.showStandardModal = false;
      this.$emit("update");
    },
    openDeleteModal() {
      this.showDeleteModal = true;
    },
    closeDeleteModal() {
      this.showDeleteModal = false;
      this.mtData = null;
      this.form = this.$form.createForm(this, { name: "add_material" });
      this.form.resetFields();
      this.$emit("update");
    },
    changeStatus() {
      query(
        "post",
        "useMachineToolForOrders",
        {
          get_new_orders: !this.row.get_new_orders,
          id: this.row.id,
        },
        {
          token: localStorage.getItem("token"),
        },
      ).then(() => {
        this.$emit("update");
      });
    },
    editMachineTool() {
      const data = this.$store.state.machineTools.hourPriceData;
      query("post", "changeCompanyMachineToolInfoV2", data, {
        token: localStorage.getItem("token"),
      }).then(() => {
        // this.serialNumber = null;
        this.$store.commit("machineTools/clearHourPriceData");
        this.$emit("update");
        // this.closeEditModal();
      });
    },
    editMachineToolFromModal() {
      const data = {
        id: this.row.id,
      };
      if (this.serialNumber) {
        data.serial_number = this.serialNumber;
      }
      if (this.idShop) {
        data.id_shop = this.idShop;
      }
      if (this.laborHourPrice) {
        data.labor_hour_price = this.laborHourPrice;
      }
      if (this.idAccClass) {
        data.id_current_accuracy_class = this.idAccClass;
      }
      query("post", "changeCompanyMachineToolInfoV2", data, {
        token: localStorage.getItem("token"),
      }).then(() => {
        this.serialNumber = null;
        this.$emit("update");
        this.closeEditModal();
      });
    },
    getShops() {
      query(
        "get",
        "getCurrentCompaniesShops",
        {},
        {
          token: localStorage.getItem("token"),
        },
      ).then((shops) => {
        this.shops = shops;
      });
    },
    saveEdit(value, name) {
      if (name === "serial_number") {
        this.serialNumber = value;
      } else if (name === "id_shop") {
        this.idShop = value;
      } else if (name === "id_current_accuracy_class") {
        this.idAccClass = value;
      }
    },
    setLaborHour(val) {
      this.laborHourPrice = val;
      this.resetTimer();
    },
    startTimer() {
      this.timer = setTimeout(() => {
        this.editMachineTool();
      }, 600);
    },
    stopTimer() {
      clearTimeout(this.timer);
      this.timer = null;
    },
    resetTimer() {
      const data = {
        id: this.row.id,
      };
      if (this.serialNumber) {
        data.serial_number = this.serialNumber;
      }
      if (this.idShop) {
        data.id_shop = this.idShop;
      }
      if (this.laborHourPrice) {
        data.labor_hour_price = this.laborHourPrice;
      }
      this.$store.commit("machineTools/setHourPriceData", data);
      this.stopTimer();
      this.startTimer();
    },
    deleteMachineTool() {
      query(
        "delete",
        "deleteMachineToolFromCompany",
        {},
        {
          token: localStorage.getItem("token"),
          id: this.row.id,
        },
      ).then(() => {
        this.$emit("update");
        this.closeDeleteModal();
      });
    },
    getAccuracyClasses() {
      query(
        "get",
        "getAccuracyClasses",
        {},
        {
          token: localStorage.getItem("token"),
        },
      ).then((res) => {
        this.accuracyClasses = res;
      });
    },
    setShowMaterialModal(val) {
      this.showMaterialModal = val;
      if (val) {
        this.getToolData();
        this.getMaterialsList();
      }
    },
    getToolData() {
      query(
        "post",
        "getMaterialsForMachineTool",
        {
          id_machine_tool: this.row.id,
        },
        {
          token: localStorage.getItem("token"),
        },
      ).then((res) => {
        this.mtData = res.materials;
      });
    },
    getMaterialsList() {
      query("get", "getMaterialTree", {}, {}).then((res) => {
        this.materialsData = res;
      });
    },
    saveMaterialForMt(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          query(
            "post",
            "addMaterialForMachineTool",
            {
              id_machine_tool: this.row.id,
              materials: values.materials,
            },
            {
              token: localStorage.getItem("token"),
            },
          ).then(() => {
            this.setShowMaterialModal(false);
            this.$emit("update");
          });
        }
      });
    },
  },
  created() {
    this.getAccuracyClasses();
  },
  components: {
    Input,
    Button,
    Modal,
    Select,
  },
};
</script>
