import Vue from "vue";
import VueRouter from "vue-router";
import pages from "@/assets/pages.json";
import MachineTools from "@/pages/manufacture/machineTools/MachineTools.vue";
import OperationalPlanning from "@/pages/manufacture/operationalPlanning/OperationalPlanning.vue";
import Orders from "@/pages/manufacture/outgoingOrders/OutgoingOrders.vue";
import Page404 from "@/components/404/Page404.vue";
import User from "@/pages/manufacture/user/User.vue";
import Home from "@/pages/manufacture/home/Home.vue";
import Files from "@/pages/manufacture/files/Files.vue";
import Parts from "@/pages/manufacture/parts/Parts.vue";
import AllParts from "@/pages/expert/parts/Parts.vue";
import IncomingOrders from "@/pages/manufacture/incomingOrders/IncomingOrders.vue";
import Bills from "@/pages/manufacture/bills/Bills.vue";
import Reports from "@/pages/manufacture/reports/Reports.vue";
import Contracts from "@/pages/manufacture/contracts/Contracts.vue";
import ManufacturerSearch from "@/pages/manufacture/manufacturerSearch/ManufacturerSearch.vue";
import OrderOffers from "@/pages/manufacture/orderOffers/OrderOffers.vue";
import Workshops from "@/pages/manufacture/workshops/Workshops.vue";
import TechnologicalProcesses from "@/pages/manufacture/technologicalProcesses/TechnologicalProcesses.vue";
import ToolTestReports from "@/pages/manufacture/toolTestReports/ToolTestReports.vue";
import Blanks from "@/pages/manufacture/blanks/Blanks.vue";
import Fixtures from "@/pages/manufacture/fixtures/Fixtures.vue";
import Tools from "@/pages/manufacture/tools/Tools.vue";
import MeasuringTools from "@/pages/manufacture/measuringTools/MeasuringTools.vue";
import PartsOnWarehouse from "@/pages/manufacture/partsOnWarehouse/PartsOnWarehouse.vue";
import Users from "@/pages/manufacture/users/Users.vue";
import UserRoles from "@/pages/manufacture/userRoles/UserRoles.vue";
import Materials from "@/pages/manufacture/materials/Materials.vue";
import Standards from "@/pages/manufacture/standards/Standards.vue";
import TimeStandards from "@/pages/manufacture/timeStandards/TimeStandards.vue";
import LocalStandards from "@/pages/manufacture/localStandards/LocalStandards.vue";
import CostCalculation from "@/pages/manufacture/costCalculation/CostCalculation.vue";
import Billing from "@/pages/manufacture/billing/Billing.vue";
import AllFiles from "@/pages/expert/files/Files.vue";
import AllUsers from "@/pages/administration/users/Users.vue";
import AllOrganizations from "@/pages/administration/organizations/Organizations.vue";
import AllOrders from "@/pages/administration/allOrders/AllOrders.vue";
import CompanyProfile from "@/pages/manufacture/companyProfile/CompanyProfile.vue";
import CreateOrder from "@/pages/manufacture/createOrder/CreateOrder.vue";
import Login from "@/pages/all/login/Login.vue";
import Order from "@/pages/manufacture/order/Order.vue";
import Recommendations from "@/pages/manufacture/recommendations/Recommendations.vue";
import TechProcesses from "@/pages/manufacture/techProcesses/TechProcesses.vue";
import TechReq from "@/pages/manufacture/technical_requirements/TechnicalRequirements.vue";
import Analytics from "@/pages/administration/analytics/Analytics.vue";
import AllTools from "@/pages/expert/tools/Tools.vue";
import AllMachineTools from "@/pages/expert/machineTools/machineTools.vue";
import AllMaterials from "@/pages/expert/materials/Materials.vue";
import ExpStandards from "@/pages/expert/standards/Standards.vue";
import ToolsNomenclature from "@/pages/expert/ToolsNomenclature/ToolsNomenclature.vue";
import ToolsCatalogue from "@/pages/manufacture/toolsCatalogue/ToolsCatalogue.vue";
import ToolHistory from "@/pages/manufacture/toolHistory/ToolHistory.vue";
import CuttingConditions from "@/pages/expert/tools/components/cuttingConditions/CuttingConditions.vue";
import Assembly from "@/pages/expert/tools/components/assembly/Assembly.vue";
import MTNomenclature from "@/pages/expert/MTNomenclature/MTNomenclature.vue";
import BlankTypes from "@/pages/expert/blankTypes/BlankTypes.vue";
import MeasuringToolsCatalogue from "@/pages/expert/measuringToolsCatalogue/MeasuringToolsCatalogue.vue";
import FixtureCatalogue from "@/pages/expert/fixtureCatalogue/FixtureCatalogue.vue";
import WBTypes from "@/pages/expert/wbTypes/WBTypes.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: pages.home,
  },
  {
    path: "/machine-tools",
    name: "machineTools",
    component: MachineTools,
    meta: pages["machine-tools"],
  },
  {
    path: "/measuring-tools",
    name: "Measuring tools",
    component: MeasuringTools,
    meta: pages["measuring-tools"],
  },
  {
    path: "/operational_planning",
    name: "operationalPlanning",
    component: OperationalPlanning,
    meta: pages.operational_planning,
  },
  {
    path: "/outgoing_orders",
    name: "outgoingOrders",
    component: Orders,
    meta: pages.outgoing_orders,
  },
  /* {
    path: '/allOrders/specification/:id',
    name: 'spec',
    component: Specification,
    meta: {
      auth: true,
      layout: 'personal',
      prod: true,
      user: false,
      manufacturer: false,
      admin: true,
      expert: false,
    },
  },
  {
    path: '/manufacturer_offers',
    name: 'manufacturerOffers',
    component: Specification,
    meta: {
      auth: true,
      layout: 'personal',
      prod: false,
      user: false,
      manufacturer: true,
      admin: true,
      expert: false,
    },
  }, */
  {
    path: "/user_profile",
    name: "userProfile",
    component: User,
    meta: pages.user_profile,
  },
  {
    path: "/files",
    name: "files",
    component: Files,
    meta: pages.files,
  },
  {
    path: "/parts",
    name: "parts",
    component: Parts,
    meta: pages.parts,
  },
  {
    path: "/all_parts",
    name: "allParts",
    component: AllParts,
    meta: pages.all_parts,
  },
  {
    path: "/reports",
    name: "reports",
    component: Reports,
    meta: pages.reports,
  },
  {
    path: "/incoming_orders",
    name: "incomingOrders",
    component: IncomingOrders,
    meta: pages.incoming_orders,
  },
  {
    path: "/bills",
    name: "bills",
    component: Bills,
    meta: pages.bills,
  },
  {
    path: "/contracts",
    name: "contracts",
    component: Contracts,
    meta: pages.contracts,
  },
  {
    path: "/manufacturer_search",
    name: "manufacturerSearch",
    component: ManufacturerSearch,
    meta: pages.manufacturer_search,
  },
  {
    path: "/manufacturer_search/:id",
    name: "manufacturerSearch",
    component: ManufacturerSearch,
    meta: pages.manufacturer_search,
  },
  {
    path: "/order_offers/:id",
    name: "orderOffers",
    component: OrderOffers,
    meta: pages.order_offers,
  },
  {
    path: "/workshops",
    name: "workshops",
    component: Workshops,
    meta: pages.workshops,
  },
  {
    path: "/technological-processes",
    name: "technologicalProcesses",
    component: TechnologicalProcesses,
    meta: pages["technological-processes"],
  },
  {
    path: "/tool_test_reports",
    name: "toolTestReports",
    component: ToolTestReports,
    meta: pages.tool_test_reports,
  },
  {
    path: "/blanks",
    name: "blanks",
    component: Blanks,
    meta: pages.blanks,
  },
  {
    path: "/fixtures",
    name: "fixtures",
    component: Fixtures,
    meta: pages.fixtures,
  },
  {
    path: "/tools",
    name: "tools",
    component: Tools,
    meta: pages.tools,
  },
  {
    path: "/parts_on_warehouse",
    name: "partsOnWarehouse",
    component: PartsOnWarehouse,
    meta: pages.parts_on_warehouse,
  },
  {
    path: "/users",
    name: "users",
    component: Users,
    meta: pages.users,
  },
  {
    path: "/user_roles",
    name: "userRoles",
    component: UserRoles,
    meta: pages.user_roles,
  },
  {
    path: "/materials",
    name: "Materials",
    component: Materials,
    meta: pages.materials,
  },
  {
    path: "/standards",
    name: "Standards",
    component: Standards,
    meta: pages.standards,
  },
  {
    path: "/time_standards",
    name: "TimeStandards",
    component: TimeStandards,
    meta: pages.time_standards,
  },
  {
    path: "/locale_standards",
    name: "LocaleStandards",
    component: LocalStandards,
    meta: pages.locale_standards,
  },
  {
    path: "/cost_calculation",
    name: "CostCalculation",
    component: CostCalculation,
    meta: pages.cost_calculation,
  },
  {
    path: "/billing",
    name: "Billing",
    component: Billing,
    meta: pages.billing,
  },
  {
    path: "/all_files",
    name: "AllFiles",
    component: AllFiles,
    meta: pages.all_files,
  },
  {
    path: "/all_tools",
    name: "AllTools",
    component: AllTools,
    meta: pages.all_tools,
  },
  {
    path: "/all_tools/cutting_conditions/:id",
    name: "AllToolsCuttingConditions",
    component: CuttingConditions,
    meta: pages.all_tools_cutting_conditions,
  },
  {
    path: "/all_tools/assembly/:id",
    name: "AllToolsAssembly",
    component: Assembly,
    meta: pages.all_tools_assembly,
  },
  {
    path: "/all_machine_tools",
    name: "AllMachineTools",
    component: AllMachineTools,
    meta: pages.all_machine_tools,
  },
  {
    path: "/all_materials",
    name: "AllMaterials",
    component: AllMaterials,
    meta: pages.all_materials,
  },
  {
    path: "/t_nomenclature",
    name: "ToolsNomenclature",
    component: ToolsNomenclature,
    meta: pages.t_nomenclature,
  },
  {
    path: "/mt_types",
    name: "MTNomenclature",
    component: MTNomenclature,
    meta: pages.mt_types,
  },
  {
    path: "/fixture_catalogue",
    name: "FixtureCatalogue",
    component: FixtureCatalogue,
    meta: pages.fixture_catalogue,
  },
  {
    path: "/all_measure_tools_catalogue",
    name: "MeasuringToolsCatalogue",
    component: MeasuringToolsCatalogue,
    meta: pages.all_measure_tools_catalogue,
  },
  {
    path: "/mt_working_bodies",
    name: "MTWorkingBodies",
    component: WBTypes,
    meta: pages.mt_working_bodies,
  },
  {
    path: "/blank_types",
    name: "BlankTypes",
    component: BlankTypes,
    meta: pages.blank_types,
  },
  {
    path: "/exp_standards",
    name: "ExpStandards",
    component: ExpStandards,
    meta: pages.exp_standards,
  },
  {
    path: "/exp_standards",
    name: "ExpStandards",
    component: ExpStandards,
    meta: pages.exp_standards,
  },
  {
    path: "/all_users",
    name: "AllUsers",
    component: AllUsers,
    meta: pages.all_users,
  },
  {
    path: "/company_profile",
    name: "CompanyProfile",
    component: CompanyProfile,
    meta: pages.company_profile,
  },
  {
    path: "/all_organizations",
    name: "AllOrganizations",
    component: AllOrganizations,
    meta: pages.all_organizations,
  },
  {
    path: "/all_orders",
    name: "AllOrders",
    component: AllOrders,
    meta: pages.all_orders,
  },
  {
    path: "/create_order/:id",
    name: "CreateOrder",
    component: CreateOrder,
    meta: pages.create_order,
  },
  {
    path: "/order/:id",
    name: "Order",
    component: Order,
    meta: pages.order,
  },
  {
    path: "/view-order/:id",
    name: "ViewOrder",
    component: Order,
    meta: pages["view-order"],
  },
  {
    path: "/recommendations/:id",
    name: "Recommendations",
    component: Recommendations,
    meta: pages.recommendations,
  },
  {
    path: "/tech-processes/:id",
    name: "TechProcesses",
    component: TechProcesses,
    meta: pages["tech-processes"],
  },
  {
    path: "/order-tech-requirement/:uuid/:page",
    name: "TechRequirements",
    component: TechReq,
    meta: pages["tech-req"],
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: pages.login,
  },
  {
    path: "/admin_analytics",
    name: "Analytics",
    component: Analytics,
    meta: pages.analytics,
  },
  {
    path: "/tools_catalogue",
    name: "Tools catalogue",
    component: ToolsCatalogue,
    meta: pages.tools_catalogue,
  },
  {
    path: "/tool_history/:id",
    name: "Tool history",
    component: ToolHistory,
    meta: pages.tool_history,
  },
  {
    path: "*",
    name: "404",
    component: Page404,
    meta: { layout: "personal", access: "all" },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
